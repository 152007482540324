import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import './style.css'
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandshake, faLocationDot, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';


const productSuggestions = [
    "Abricot", "Agneau", "Ail", "Airelle", "Algue", "Alkékenge", "Amélanche", "Ananas",
    "Arbouse", "Aromate", "Artichaut", "Asimine", "Asperge", "Aubergine", "Bambou", "Banane",
    "Barbadine", "Basilic", "Bergamote", "Betterave", "Bigarade", "Blé", "Blette", "Boeuf",
    "Bouture", "Brède", "Brocoli", "Brugnon", "Bunias d'orient", "Butternut", "Calebasse",
    "Canneberge", "Câpre", "Capucine tubéreuse", "Carambole", "Cardon", "Carotte", "Cassis",
    "Céleri", "Céleri-rave", "Cèpes", "Cerfeuil tubéreux", "Cerise", "Champignon de paris",
    "Champignons noirs", "Chanterelles", "Chaperon", "chataîgne", "Chayote", "Chervi",
    "Chicon", "Chicorée", "Chou", "Chou blanc", "Chou cabus", "Chou chinois",
    "Chou de Bruxelles", "Chou frisé", "Chou kale", "Chou navet", "Chou palmier",
    "Chou rave", "Chou romanesco", "Chou rouge", "Chou-fleur", "Ciboule",
    "Ciboulette", "Citrouille", "Clémentine", "Coeur de palmier", "Coing",
    "Combava", "Concombre", "Confiture", "Coqueret du pérou", "Cornichon",
    "Cornouille", "courge", "Courge cireuse", "Courge de siam", "Courge musquée",
    "Courge spaghetti", "Courge sucrine du berry", "Courgette", "Cresson",
    "Curcuma frais", "Cynorrhodon", "Durian", "Échalote", "Edamame", "Endive",
    "endive", "Epinard", "epinard", "Epine-vinette", "Fane de carotte",
    "Fane de radis", "Farine", "Fe'i", "Feijoa", "fenouil", "Fève",
    "Ficoïde glaciale", "Figue", "Figue de Barbarie", "Fleur", "Fleur comestible",
    "Foin", "Fraise", "Framboise", "Frisée", "Fromage", "Fruit",
    "Fruit de la passion", "Fruit de mer", "Fruit-à-pain", "Fumier",
    "Germes de soja", "Gingembre frais", "Girembelle", "Girolle", "Glycine tubéreuse",
    "Gombo", "Gourde", "Goyave", "Graine", "Graine germée", "Grapefruit",
    "Grelo", "Grenade", "Grenadelle", "Grenadille", "Griotte", "Groseille",
    "Haricot beurre", "Haricot plat", "Haricot vert", "Huile olive", "Icaque",
    "Jambose rouge", "Jaque", "Jujube", "Jus", "Kaki", "Kancon",
    "Kiwano", "Kiwi", "Kombu", "Konjac", "Kumquat", "Lait",
    "Laitue", "Laurier", "lavande", "Légume", "Litchi", "Longane",
    "Luffa", "Lys comestible", "Maceron", "Mâche", "Mandarine", "Mangoustan",
    "Mangue", "Maracudja", "marron", "Mauve", "Melon", "Mesclun",
    "Miel", "Mini-épi de maïs", "Mirabelle", "Mizuna", "Mombin", "Momordique",
    "Morilles", "moule", "Mûre", "Myrobolan", "Myrte", "Myrtille",
    "Navet", "Nèfle", "noisette", "Noix", "Nombril de vénus", "Oca du pérou",
    "Oeuf", "Oignon", "Oignon de chine", "Oignon grelot", "Oignon nouveau", "Okra",
    "Orange", "Ortie", "Oseille", "Paille", "Pain de singe", "Pak choï",
    "Papaye", "Passiflores", "Pastèque", "Pâtisson", "Pêche", "Pepino",
    "Périlla", "persil", "Petits pois", "Physalis", "Piment", "Pissenlit",
    "Pitaya", "Plant", "Plante", "Plaquebière", "Pleurote", "Poire",
    "Poireau", "Pois gourmand", "Poisson", "Poivron", "Pomelo", "Pomme",
    "Pomme de terre", "Potimarron", "Potiron", "Poule", "Poulet", "Pourpier",
    "Prune", "Prune de cythère", "Prune reine claude", "Quenette", "Quetsche", "Radis",
    "Radis mougri", "Radis noir", "Raisan blanc", "Raisin", "Raisin noir", "Ramboutan",
    "Réglisse", "Rhubarbe", "Romaine", "Romarin", "Roquette", "Rutabaga",
    "Safran", "Salade", "Salade verte", "Salak", "Salicorne", "Salsifi",
    "Sapote", "Sapotille", "Sauce", "Scarole", "Shiso", "Steak",
    "Steak haché", "Tamarillo", "Tamarin", "Taxo", "Tomate", "Tomate cerise",
    "Truffe", "Truite", "Veau", "Viande", "Vin", "Volaille",
    "Wakame", "Yuzu"
];

const SearchBar = ({
    small,
    iframe
}) => {
    const [searchMainType, setSearchMainType] = useState('offer');
    const [searchProductName, setSearchProductName] = useState('');
    const [searchCity, setSearchCity] = useState('');
    const [searchPostalCode, setSearchPostalCode] = useState('');
    const [searchType, setSearchType] = useState('');
    const [searchCoordinates, setSearchCoordinates] = useState([]);
    const [searchDistance, setSearchDistance] = useState('');
    const [searchQueryLocation, setSearchQueryLocation] = useState('');
    const [suggestedProducts, setSuggestedProducts] = useState([]);
    const [closeSuggestionsTimeout, setCloseSuggestionsTimeout] = useState(null);

    const [suggestedCities, setSuggestedCities] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    const handleSubmit = (e) => {
        e.preventDefault();
        const url = `/annonces?mainType=${searchMainType}&productName=${searchProductName}&city=${searchCity}&postalCode=${searchPostalCode}&type=${searchType}&coordinates=${searchCoordinates}&distance=${searchDistance}`;
        if (location.pathname.includes('/iframe')) {
            window.top.location.href = url;
            return;
        }
        navigate(url);
    };

    const updateProductSuggestions = (query) => {
        const lowerCaseQuery = query.toLowerCase();
        const matchedSuggestions = productSuggestions.filter(product =>
            product.toLowerCase().startsWith(lowerCaseQuery)
        );
        setSuggestedProducts(matchedSuggestions);
    };

    const fetchCitySuggestions = async (query) => {
        try {
            const response = await axios.get('https://api-adresse.data.gouv.fr/search/', {
                params: { q: query, type: 'municipality', limit: 100 },
            });

            const refinedSuggestions = response.data.features
                .map((suggestion) => {
                    let displayText = '';

                    if (suggestion.properties.type === 'municipality') {
                        displayText = `${suggestion.properties.city} (${suggestion.properties.postcode})`;
                    } else if (suggestion.properties.type === 'postcode' || suggestion.properties.type === 'departement') {
                        displayText = `${suggestion.properties.context.split(',')[0]} (${suggestion.properties.postcode})`;
                    }

                    return { ...suggestion, displayText };
                })
                .filter((suggestion) => suggestion.displayText) // Enlève les éléments vides
                .sort((a, b) => a.displayText.localeCompare(b.displayText))
                .slice(0, 10); // Prend seulement les 10 premiers éléments

            setSuggestedCities(refinedSuggestions);
        } catch (error) {
            console.error('Error fetching city suggestions:', error);
        }
    };

    const handleCitySuggestionClick = (suggestion) => {

        if (suggestion.properties.type === 'municipality') {
            setSearchCity(suggestion.properties.city);
            setSearchPostalCode(suggestion.properties.postcode);
            setSearchQueryLocation(suggestion.properties.city);
        } else if (suggestion.properties.type === 'postcode' || suggestion.properties.type === 'departement') {
            setSearchCity(suggestion.properties.context.split(',')[0]);
            setSearchQueryLocation(suggestion.properties.context.split(',')[0])
            setSearchPostalCode(suggestion.properties.postcode);
        }
        setSearchCoordinates(suggestion.geometry.coordinates)
        setSuggestedCities([]);
    };

    const handleBlur = () => {
        const timeoutId = setTimeout(() => {
            setSuggestedProducts([]);
            setSuggestedCities([]);
        }, 800);
        setCloseSuggestionsTimeout(timeoutId);
    };

    const handleFocus = () => {
        if (closeSuggestionsTimeout) {
            clearTimeout(closeSuggestionsTimeout);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="search-bar">
            <div className="search-bar-container-radio">
                <div className="search-bar-radio">
                    <input
                        className="search-bar-input-ratio me-1"
                        id="search-bar-ratio-offer"
                        type="radio"
                        value="offer"
                        checked={searchMainType === 'offer'}
                        onChange={() => setSearchMainType('offer')}
                    />
                    <label className="search-bar-input-ratio-label" for="search-bar-ratio-offer">Offres</label>
                </div>
                <div className="search-bar-radio">
                    <input
                        className="search-bar-input-ratio me-1"
                        id="search-bar-ratio-aske"
                        type="radio"
                        value="asked"
                        checked={searchMainType === 'asked'}
                        onChange={() => setSearchMainType('asked')}
                    />
                    <label className="search-bar-input-ratio-label" for="search-bar-ratio-aske">Demandes</label>
                </div>
            </div>
            <div className="search-bar-container-input">
                <div className="search-bar-input-container">
                    <FontAwesomeIcon icon={faMagnifyingGlass} style={{ marginRight: '0.5rem', color: "#ffffff", }} />
                    <div className='w-100 position-relative'>

                        <input
                            className="search-bar-input"
                            type="text"
                            value={searchProductName}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            onChange={(e) => {
                                setSearchProductName(e.target.value);
                                updateProductSuggestions(e.target.value);
                            }}
                            placeholder="Que recherchez-vous ?"
                        />
                        {suggestedProducts?.length > 0 &&
                            <div className="search-bar-input-dropdown">
                                {suggestedProducts.map((suggestion, index) => (
                                    <div
                                        key={index}
                                        className="search-bar-input-dropdown-item"
                                        onClick={() => {
                                            setSearchProductName(suggestion);
                                            setSuggestedProducts([]);
                                        }}
                                    >
                                        {suggestion}
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <div className="search-bar-location-container">
                    <FontAwesomeIcon icon={faLocationDot} size="lg" style={{ marginRight: '0.5rem', color: "#ffffff", }} />
                    <div className='w-100 d-flex position-relative'>

                        <input
                            className="search-bar-input"
                            type="text"
                            value={searchQueryLocation}
                            onBlur={handleBlur}
                            onFocus={handleFocus}
                            onChange={(e) => {
                                setSearchQueryLocation(e.target.value);
                                fetchCitySuggestions(e.target.value);
                            }}
                            placeholder="Localisation"
                        />
                        {suggestedCities?.length > 0 &&
                            <div className="search-bar-input-dropdown">

                                {suggestedCities.map((sugestion) => (
                                    <div
                                        key={sugestion.properties.id}
                                        className="search-bar-input-dropdown-item"
                                        onClick={() => handleCitySuggestionClick(sugestion)}
                                    >
                                        {sugestion.displayText}
                                    </div>
                                ))}
                            </div>
                        }
                    </div>
                    {
                        searchQueryLocation && (
                            <select onChange={(e) => setSearchDistance(e.target.value)} className="search-bar-location-select">
                                <option value={5}>5km</option>
                                <option value={10}>10km</option>
                                <option value={25}>25km</option>
                                <option value={50}>50km</option>

                            </select>
                        )
                    }
                </div>
                {searchMainType !== 'asked' &&
                    <div>
                        <FontAwesomeIcon icon={faHandshake} size="lg" style={{ marginRight: '0.5rem', color: "#ffffff", }} />
                        <select
                            className="search-bar-input-select"
                            value={searchType}
                            onChange={(e) => { setSearchType(e.target.value) }}
                        >
                            <option value={''}>Type d'annonces</option>
                            <option value="sell">Vente</option>
                            <option value="trade">Troc</option>
                            <option value="donation">Don</option>
                        </select>
                    </div>
                }
            </div>
            <div className="search-bar-container-select">

            </div>
            <button className="search-bar-button" type="submit">Rechercher</button>
        </form>
    );
};

export default SearchBar;
