import React, { useState } from 'react';

import './style.css'

const AddAdminForm = ({ onSubmit }) => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    address: {
      streetNumber: '',
      streetName: '',
      city: '',
      postalCode: ''
    },
  });

  const handleChange = (e) => {
    if (e.target.dataset.addressField) {
      setFormData({
        ...formData,
        address: {
          ...formData.address,
          [e.target.name]: e.target.value,
        },
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form className="profile-admin-admins-form" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="firstName">Prénom</label>
        <input
          type="text"
          name="firstName"
          id="firstName"
          value={formData.firstName}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="lastName">Nom</label>
        <input
          type="text"
          name="lastName"
          id="lastName"
          value={formData.lastName}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          value={formData.email}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="password">Mot de passe</label>
        <input
          type="password"
          name="password"
          id="password"
          value={formData.password}
          onChange={handleChange}
          required
        />
      </div>

      <div>
        <label htmlFor="city">Ville</label>
        <input
          type="text"
          name="city"
          id="city"
          data-address-field
          value={formData.address.city}
          onChange={handleChange}
          required
        />
      </div>
      <div>
        <label htmlFor="postalCode">Code postal</label>
        <input
          type="text"
          name="postalCode"
          id="postalCode"
          data-address-field
          value={formData.address.postalCode}
          onChange={handleChange}
          required
        />
      </div>

      <button type="submit">Ajouter</button>
    </form>
  );
};

export default AddAdminForm;