import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { Carousel } from 'react-responsive-carousel';
import Modal from 'react-modal';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FacebookShareButton, FacebookIcon } from "react-share";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faCommentAlt, faFlag, faPhone, faTrash } from '@fortawesome/free-solid-svg-icons';

import 'react-responsive-carousel/lib/styles/carousel.min.css';
import './style.css';
import { useAuth } from '../../contexts/AuthContext';

import { faStar } from '@fortawesome/free-solid-svg-icons';
import useNavigateCustom from '../../hooks/useNavigateCustom';
import { Helmet } from 'react-helmet';
//import { Adsense } from '@ctrl/react-adsense';

const AdDetails = () => {
  const { adId } = useParams();
  const [ad, setAd] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [modalReportIsOpen, setModalReportIsOpen] = useState(false);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [reportReason, setReportReason] = useState('');
  const [deleteReason, setDeleteReason] = useState('');
  const [isFavori, setIsFavori] = useState(false);

  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { navigateHome } = useNavigateCustom();
  const { user: me, isAuthenticated, isAdmin } = useAuth();

  let url = null
  if (ad) {
    url = `${window.location.origin}/ad/${ad._id}`; // L'URL de l'annonce à partager
  }

  const [userAds, setUserAds] = useState([]);

  useEffect(() => {
    if (ad && ad.user) {
      fetchUserAds();
      axios.get(`${process.env.REACT_APP_API_URL}/api/favorites`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => setIsFavori(response.data.some(({ ad }) => ad._id === adId)));
    }
  }, [ad]);



  const fetchUserAds = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/adverts`, {
        params: { page: 1, limit: 100, user: ad.user._id },
      });
      if (response?.data?.ads) {
        const filtredAds = response?.data?.ads.filter(ad => ad._id !== adId);
        setUserAds(filtredAds);
      }
    } catch (error) {
      console.error('Error fetching latest ads:', error);
    }
  };

  useEffect(() => {
    const fetchAdDetails = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/adverts/${adId}`
        );
        setAd(response.data.ad);
      } catch (error) {
        console.error('Error fetching ad details:', error);
        navigateHome();
      }
    };

    fetchAdDetails();
  }, [adId]);

  const handleArchiveClick = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/adverts/${adId}/archive`, { isArchived: !!!ad.isArchived }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`Annonce ${!ad.isArchived ? 'Archivée' : 'Activée'} avec succès`, {
        position: toast.POSITION.TOP_CENTER
      });
      // Rediriger vers la page d'accueil après un délai pour permettre à l'utilisateur de voir le toast
      setTimeout(() => {
        navigateHome();
      }, 1000);
    } catch (error) {
      toast.error('Une erreur est survenue lors de l\'archivage de l\'annonce', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  const initials = (user) => `${user.firstName?.charAt(0)}${user.lastName?.charAt(0)}`.toUpperCase();

  const handleContact = (adId) => {
    navigate(`/messages/${adId}`);
  };

  const handleReportClick = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/api/reports`, {
        type: 'ad',
        entityId: adId,
        reason: reportReason,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`Annonce signalée avec succès`, {
        position: toast.POSITION.TOP_CENTER
      });
      setModalReportIsOpen(false);

    } catch (error) {
      toast.error('Une erreur est survenue lors de l\'archivage de l\'annonce', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  const traslateAdType = () => {
    if (ad.type === 'trade') {
      return 'Troc'
    }
    if (ad.type === 'donation') {
      return 'Don'
    }

    return 'Vente'
  };

  const translatePriceType = (priceType) => {
    if (priceType === 'gram') {
      return 'g';
    }
    if (priceType === 'unit') {
      return 'Pièce(s)';
    }
    if (priceType === 'box') {
      return 'Caisse(s)';
    }
    if (priceType === 'kilo') {
      return 'Kg';
    }
    if (priceType === 'liter') {
      return 'L';
    }
    if (priceType === 'basket') {
      return 'Panier(s)';
    }

  }

  if (!ad) {
    return <div>Loading...</div>;
  }

  const handleDelete = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/advert/${adId}`, { status: "deleted", deleteReason }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success('Annonce supprimé avec succès', {
        position: toast.POSITION.TOP_CENTER
      });
      setTimeout(() => {
        navigateHome();
      }, 1000);
      setModalDeleteIsOpen(false);
    } catch (error) {
      console.error('Erreur lors de l\'acceptation de l\'annonce:', error);
    }
  };

  const toggleFavori = () => {
    if (isFavori) {
      axios.delete(`${process.env.REACT_APP_API_URL}/api/favorites/${adId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(response => {
          toast.success(`Annonce retirée des favoris`, {

            position: toast.POSITION.TOP_CENTER
          });

          setIsFavori(false)
        });
      return;
    }

    axios.post(`${process.env.REACT_APP_API_URL}/api/favorites`, {
      ad: adId,
    }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then(response => {
        toast.success(`Annonce ajoutée aux favoris`, {
          position: toast.POSITION.TOP_CENTER
        });
        setIsFavori(true)
      });
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  return (
    <>
      <Helmet>
        <title>{ad.productName}</title>
        <meta property="og:title" content={ad.productName} />
        <meta property="og:description" content={ad.description} />
        <meta property="og:image" content={`${process.env.REACT_APP_API_URL}/${ad.images[0]}`} />
        <meta property="og:url" content={url} />
        <meta property="og:type" content="article" />
      </Helmet>
      <div className="annoncement-detail-container">
        {ad.user.professional && <span className="badge-pro">PRO</span>}


        <p className='annoncement-detail-name'>{ad.productName}
          {isAuthenticated && <FontAwesomeIcon
            icon={faStar}
            size="2xs"
            onClick={toggleFavori}
            color={isFavori ? "#ffd303" : "#8c8c8c"}
          />}</p>
        <p className='annoncement-detail-location'> à {ad.location.city} ({ad.location.postalCode}) <span style={{ fontWeight: "normal" }}>le {formatDate(ad.createdAt)}</span></p>

        <div className="annoncement-detail-top">
          <div className="annoncement-detail-left">


            <div className='annoncement-detail-carousel-container'>

              <Carousel showThumbs={false}>
                {ad.images.map((image, index) => (
                  <img

                    key={index}
                    src={`${process.env.REACT_APP_API_URL}/${image}`
                    }
                    alt={`Image ${index + 1}`}
                  />
                ))}
              </Carousel>
            </div>

          </div>
          <div className="annoncement-detail-right">
            <div className="annoncement-detail-right-details">
              <div>
                <div className="annoncement-profile-image-container">
                  {ad.user.profileImage ? (
                    <img className="annoncement-profile-image" src={`${process.env.REACT_APP_API_URL}${ad.user.profileImage}`} alt="User profile" />
                  ) : (
                    <div className="annoncement-profile-placeholder">{initials(ad.user)}</div>
                  )}
                </div>
                <p className="annoncement-detail-seller-details-name">{ad.user.firstName}</p>
                {ad.user.professional ?
                  <>
                    <p className="annoncement-detail-seller-details-type">Producteur professionnel</p>
                    <p className="annoncement-detail-seller-details-company">{ad.user.companyName}</p>
                    <p className="annoncement-detail-seller-details-siret">Siret : {ad.user.companySiret}</p>
                  </>
                  : <p className="annoncement-detail-seller-details-type">Jardinier particulier</p>}
                {me.role === 0 && <div className="mt-2" style={{ textAlign: "center" }}>
                  ({ad.user.email})
                </div>}
              </div>
              <div>
                {
                  ad.mainType !== 'asked' && <div className="annoncement-detail-seller-details">
                    <p className="annoncement-detail-seller-details-name">{traslateAdType(ad.type)}</p>
                    {ad.price && <p className="annoncement-detail-seller-details-type" style={{ fontSize: '1.3rem', color: '#1a9b4a', fontWeight: 'bold' }}>{ad.price} {ad.priceType === 'kilo' ? '€ / kg' : '€'}</p>}
                    <p className="annoncement-detail-seller-details-type">Quantité: <span style={{ color: '#1a9b4a', fontWeight: 'bold' }}>{ad.quantity} {translatePriceType(ad.priceType)}</span></p>
                  </div>
                }


                <div>
                  {(!isAuthenticated || (isAuthenticated && me.id !== ad.user._id)) && <button className="button-white annoncement-detail-contact-button" onClick={() => handleContact(ad._id)}><FontAwesomeIcon className="me-2" color="#0D9B4A" icon={faCommentAlt} size="xl" />Contacter le vendeur</button>}
                  {isAuthenticated && ad.user.phone && ad.user.showPhoneNumber && (
                    <>

                      <button className="button-white annoncement-detail-contact-button" onClick={() => setShowPhoneNumber(true)}>
                        <FontAwesomeIcon icon={faPhone} className="me-2" />
                        {showPhoneNumber ? ad.user.phone : 'Voir le numéro de téléphone'}
                      </button>

                    </>
                  )}

                </div>

                <div className="annoncement-detail-share-container">
                  <FacebookShareButton
                    url={url}
                    quote={ad.productName}
                    hashtag="#VenteDeProduits"
                    className="annoncement-detail-contact-button"
                  >
                    <FacebookIcon size={32} round={true} />
                    <span className="annoncement-detail-contact-button-fb ms-1" style={{ color: "#0b9b4a" }} >Partager sur Facebook</span>
                  </FacebookShareButton>
                </div>


                <div style={{ color: "#195a21", display: "flex", justifyContent: "center" }}>
                  {isAuthenticated && isAdmin &&
                    <span title="Supprimer">
                      <FontAwesomeIcon style={{ marginRight: '0.5rem', cursor: "pointer" }} size="xl" icon={faTrash} onClick={() => setModalDeleteIsOpen(true)} />
                    </span>
                  }
                  {isAuthenticated && (me.id === ad.user._id) && ad.status === 'accepted' &&
                    <span title={!ad.isArchived ? 'Archiver l\'annonce' : 'Activer l\'annonce'}>
                      <FontAwesomeIcon style={{ marginRight: '0.5rem', cursor: "pointer" }} size="xl" icon={faArchive} onClick={() => setModalIsOpen(true)} />
                    </span>
                  }
                  {isAuthenticated && me.id !== ad.user._id &&
                    <span title="Signaler">
                      <FontAwesomeIcon style={{ marginRight: '0.5rem', cursor: "pointer" }} size="xl" icon={faFlag} onClick={() => setModalReportIsOpen(true)} />
                    </span>
                  }

                </div>

              </div>

            </div>



          </div>
        </div>

        <div className='annoncement-detail-info'>
          <p className="annoncement-detail-info-description mb-4">Description</p>

          <p className="annoncement-detail-info-description-content">
            {ad.description}
          </p>
        </div>
        <div className="w-100">
          {/* <Adsense
            client="ca-pub-5061084407043738"
            slot="5343881586"
            layoutKey="-gu+1k+4y-4o-12"
            format="fluid"
            style={{ display: 'block' }}
          /> */}

          {!!userAds?.length &&
            <div>
              <p className="annoncement-detail-info-description mb-4">Les autres annonces du vendeur</p>
              <div className="latest-advert-container">
                {userAds.map((ad) => (
                  <Link className="latest-advert mb-3" key={ad.id} to={`/ad/${ad._id}`}>
                    <div key={ad._id} style={{ position: "relative" }} >
                      {ad.user.professional && <span className="badge-pro">Pro</span>}
                      <div className="latest-advert-container-image">
                        <img className="latest-advert-image" src={`${process.env.REACT_APP_API_URL}/${ad.images[0]}`} alt={ad.productName} />

                      </div>
                      <div >
                        <h3 className="latest-advert-product">{ad.productName}</h3>
                        <p className="latest-advert-by mb-1">Par <span className="latest-advert-by-name">{`${ad.user.firstName}`}</span></p>
                        <p className="latest-advert-at">le <span className="latest-advert-at-date">{formatDate(ad.createdAt)}</span></p>
                      </div>
                    </div>
                  </Link>
                ))}

              </div>
            </div>

          }
          {/* <Adsense
            client="ca-pub-5061084407043738"
            slot="8502427779"
            format="auto"
            responsive='true'
            style={{ display: 'block' }}
          /> */}
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Confirmation d'archivage"
        className="modal-container"
      >
        <h2 className="modal-title">{!ad.isArchived ? 'Archiver l\'annonce' : 'Activer l\'annonce'}</h2>
        <p>Êtes-vous sûr de vouloir {!ad.isArchived ? 'Archiver' : 'Activer'} cette annonce ?</p>
        <div className='modal-actions'>
          <button className="button-red-outline" onClick={() => setModalIsOpen(false)}>
            Retour
          </button>
          <button className="button-green" onClick={handleArchiveClick}>
            Valider
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalDeleteIsOpen}
        onRequestClose={() => setModalDeleteIsOpen(false)}
        contentLabel="Confirmation suppression"
        className="modal-container"
      >
        <h2 className="modal-title">Supprimer l'annonce</h2>

        <p>Indiquez une raison</p>
        <textarea
          className="modal-textarea"
          value={deleteReason}
          onChange={(e) => setDeleteReason(e.target.value)}
        />
        <div className='modal-actions'>
          <button className="button-red-outline" onClick={() => {
            setDeleteReason('')
            setModalDeleteIsOpen(false)
          }}>
            Retour
          </button>
          <button className="button-green" onClick={handleDelete}>
            Valider
          </button>
        </div>
      </Modal>
      <Modal
        isOpen={modalReportIsOpen}
        onRequestClose={() => setModalReportIsOpen(false)}
        contentLabel="Confirmation signalement"
        className="modal-container"
      >
        <h2 className="modal-title">Signaler l'annonce</h2>
        <p>Dites-nous pourquoi souhaitez-vous signaler cette annonce ?</p>
        <textarea
          className="modal-textarea"
          value={reportReason}
          onChange={(e) => setReportReason(e.target.value)}
        />
        <div className='modal-actions'>
          <button className="button-red-outline" onClick={() => {
            setReportReason('')
            setModalReportIsOpen(false)
          }}>
            Retour
          </button>
          <button disabled={!reportReason?.length} className="button-green" onClick={handleReportClick}>
            Valider
          </button>
        </div>
      </Modal>
    </>
  );
};

export default AdDetails;
