import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import { toast } from 'react-toastify';

import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const MyFavAds = () => {
  const [favoritesAds, setFavoritesAds] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/favorites`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setFavoritesAds(res.data);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);



  return (
    <div className="profile-container profile-my-annoncements">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Annonces favorites</Accordion.Header>
          <Accordion.Body>
            {
              favoritesAds?.length ?
                <table className="account-table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>Annonce</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      favoritesAds.map(({ ad }) => (
                        <tr key={ad._id}>
                          <td>{ad.productName}</td>
                          <td>
                            <Link to={`/ad/${ad._id}`}>
                              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEye} />
                            </Link>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                :
                <p>-</p>
            }
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>

  );
};

export default MyFavAds;