import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import MyAds from '../MyAds';
import ProfileEdit from '../ProfileEdit';
import { useLocation, useNavigate } from 'react-router-dom';
import './Profile.css';
import ManageAdvert from '../admin/pages/ManageAdvert';
import ManageAdmins from '../admin/pages/ManageAdmins';
import ManageUsers from '../admin/pages/ManageUsers';
import MyFavAds from '../MyFavAds';

const Profile = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState('info');

  const { isAuthenticated, isAdmin, loading } = useAuth();

  useEffect(() => {
    const tab = location.pathname.split('/').pop();
    setActiveTab(tab);
  }, [location]);

  const handleTabClick = (tab) => {
    setActiveTab(tab);
    navigate(`/profil/${tab}`);;
  };

  return (
    <div className='tabs-container'>
      <div className="tabs">
        <div
          className={`tab ${activeTab === 'info' ? 'active' : ''}`}
          onClick={() => handleTabClick('info')}
        >
          Mes informations
        </div>
        <div
          className={`tab ${activeTab === 'fav-ads' ? 'active' : ''}`}
          onClick={() => handleTabClick('fav-ads')}
        >
          Mes annonces favorites
        </div>
        <div
          className={`tab ${activeTab === 'ads' ? 'active' : ''}`}
          onClick={() => handleTabClick('ads')}
        >
          Mes annonces
        </div>
        {
          isAdmin && (
            <>
              <div
                className={`tab ${activeTab === 'admin-users' ? 'active' : ''}`}
                onClick={() => handleTabClick('admin-users')}
              >
                Gestion des utilisateurs
              </div>
              <div
                className={`tab ${activeTab === 'admin-admins' ? 'active' : ''}`}
                onClick={() => handleTabClick('admin-admins')}
              >
                Gestion des administrateurs
              </div>
              <div
                className={`tab ${activeTab === 'admin-adverts' ? 'active' : ''}`}
                onClick={() => handleTabClick('admin-adverts')}
              >
                Gestion des annonces
              </div>
            </>
          )
        }
      </div>
      {activeTab === 'info' && (
        <div>
          <ProfileEdit />
        </div>
      )}
      {activeTab === 'ads' && (
        <div>
          <MyAds />
        </div>
      )}
      {activeTab === 'fav-ads' && (
        <div>
          <MyFavAds />
        </div>
      )}
      {!loading && isAuthenticated && isAdmin &&
        <>
          {activeTab === 'admin-users' && (
            <div>
              <ManageUsers />
            </div>
          )}
          {activeTab === 'admin-admins' && (
            <div>
              <ManageAdmins />
            </div>
          )}
          {activeTab === 'admin-adverts' && (
            <div>
              <ManageAdvert />
            </div>
          )}
        </>}

    </div>
  );
};

export default Profile;