import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';

import './style.css'
import useNavigateCustom from '../../hooks/useNavigateCustom';

const SignupValidation = () => {
  const { token } = useParams();
  const [status, setStatus] = useState(null);
  const { navigateHome } = useNavigateCustom();

  useEffect(() => {
    const validateUser = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/validate/${token}`);
        setStatus(response.status);
      } catch (error) {
        console.error(error);
        setStatus(error.response.status);
      }
    };

    validateUser();
  }, [token]);

  if (status === 200) {
    return (
      <div className="signup-validation-container">
        <h1 className="signup-validation-title-success">Activation réussie</h1>
        <p className="signup-validation-text">Votre compte a été activé avec succès.</p>
        <div className="signup-validation-button-container">
          <button className="button-green" onClick={() => navigateHome()}>Retour à la page d'accueil</button>

        </div>
      </div>
    );
  } else if (status) {
    return (
      <div className="signup-validation-container">
        <h1 className="signup-validation-title-fail">Erreur d'activation</h1>
        <p className="signup-validation-text">Il y a eu un problème avec l'activation de votre compte. Veuillez réessayer plus tard.</p>
        <div className="signup-validation-button-container">
          <button className="button-green" onClick={() => navigateHome()}>Retour à la page d'accueil</button>

        </div>
      </div>
    );
  } else {
    return <div>Chargement...</div>;
  }
};

export default SignupValidation;