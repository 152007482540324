import React from 'react'
import PropTypes from 'prop-types'
import PendingAds from '../../components/PendingAds'

import './style.css';
import ReportedAds from '../../components/ReportedAds';
import { Accordion } from 'react-bootstrap';
import DeletedAds from '../../components/DeletedAds';

function ManageAdvert(props) {
  return (
    <div className="profile-container profile-admin-annoncements">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Annonces en attente de validation</Accordion.Header>
          <Accordion.Body>
            <PendingAds />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Annonces signalées</Accordion.Header>
          <Accordion.Body>
            <ReportedAds />
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Annonces supprimé</Accordion.Header>
          <Accordion.Body>
            <DeletedAds />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>


    </div>
  )
}

ManageAdvert.propTypes = {}

export default ManageAdvert
