import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Accordion from 'react-bootstrap/Accordion';
import { toast } from 'react-toastify';

import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';

const MyAds = () => {
  const [activeAds, setActiveAds] = useState([]);
  const [pendingAds, setPendingAds] = useState([]);
  const [refuseAds, setRefuseAds] = useState([]);
  const [archivedAds, setArchivedAds] = useState([]);
  const [deletedAds, setDeleteddAds] = useState([]);

  const fetchData = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/api/adverts/my-ads`, {
        headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
      });
      setActiveAds(res.data.activeAds);
      setPendingAds(res.data.pendingAds);
      setRefuseAds([...res.data.refuseAds, ...res.data.deletedAds]);
      setArchivedAds(res.data.archivedAds);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  const handleActive = async (ad) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/adverts/${ad._id}/archive`, { isArchived: !!!ad.isArchived }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      toast.success(`Annonce ${!ad.isArchived ? 'Archivée' : 'Activée'} avec succès`, {
        position: toast.POSITION.TOP_CENTER
      });
      fetchData();
    } catch (error) {
      toast.error('Une erreur est survenue lors de l\'archivage de l\'annonce', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  return (
    <div className="profile-container profile-my-annoncements">
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Annonces actives</Accordion.Header>
          <Accordion.Body>
            {
              activeAds?.length ?
                <table className="account-table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>Annonce</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      activeAds.map((ad) => (
                        <tr key={ad._id}>
                          <td>{ad.productName}</td>
                          <td>
                            <Link to={`/ad/${ad._id}`}>
                              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEye} />
                            </Link>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                :
                <p>-</p>
            }
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Annonces en attente de validation</Accordion.Header>
          <Accordion.Body>
            {
              pendingAds?.length ?
                <table className="account-table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>Annonce</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      pendingAds.map((ad) => (
                        <tr key={ad._id}>
                          <td>{ad.productName}</td>
                          <td>
                            <Link to={`/ad/${ad._id}`}>
                              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEye} />
                            </Link>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                :
                <p>-</p>
            }
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="2">
          <Accordion.Header>Annonces refusées</Accordion.Header>
          <Accordion.Body>
            {
              refuseAds?.length ?
                <table className="account-table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>Annonce</th>
                      <th>Raison du refus</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      refuseAds.map((ad) => (
                        <tr key={ad._id}>
                          <td>{ad.productName}</td>
                          <td>
                            <button className="profile-my-annoncements-active-button" onClick={() => alert(ad.deleteReason)}>Raison</button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                :
                <p>-</p>
            }
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="3">
          <Accordion.Header>Annonces archivées</Accordion.Header>
          <Accordion.Body>
            {
              archivedAds?.length ?
                <table className="account-table" style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>Annonce</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      archivedAds.map((ad) => (
                        <tr key={ad._id}>
                          <td>{ad.productName}</td>
                          <td>
                            <button className="profile-my-annoncements-active-button" onClick={() => handleActive(ad)}>Activer l'annonce</button>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                :
                <p>-</p>
            }
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );

};

export default MyAds;