import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

function b64DecodeUnicode(str) {
  // Going backwards: from bytestream, to percent-encoding, to original string.
  return decodeURIComponent(atob(str).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
}

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [user, setUser] = useState({});
  const [loading, setLoading] = useState(true);

  const token = localStorage.getItem('token');

  useEffect(() => {

    const checkAuthentication = async () => {
      if (token) {
        const decodedToken = JSON.parse(b64DecodeUnicode(token.split(".")[1]));
        const currentTime = Math.floor(Date.now() / 1000);
        if (decodedToken.exp && decodedToken.exp > currentTime) {
          const userRole = decodedToken.role;

          if (userRole === 0) {
            setIsAdmin(true);
          } else {
            setIsAdmin(false);
          }
          setUser({
            id: decodedToken.userId,
            role: decodedToken.role,
            email: decodedToken.email,
            lastName: decodedToken.lastName,
            firstName: decodedToken.firstName,
            profileImage: decodedToken.profileImage,
            address: decodedToken.address,
            isActive: decodedToken.isActive
          })
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          localStorage.removeItem("token");
        }
      } else {
        setIsAuthenticated(false);
      }
    };

    checkAuthentication();
    setLoading(false);
  }, [token]);

  const value = {
    isAuthenticated,
    setIsAuthenticated,
    isAdmin,
    user,
    loading
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};