import { useNavigate } from 'react-router-dom';

function useNavigateCustom() {

    const navigate = useNavigate();

    const navigateHome = () => {
        if (process.env.REACT_APP_NODE_ENV === 'developpement') {
            navigate('/')
        } else {
            window.location.href = 'https://jevendsmonpotager.fr/';
        }
    }
    return { navigateHome };
}

export default useNavigateCustom