import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

import './index.css';
import { faAt, faKey, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useNavigateCustom from '../../hooks/useNavigateCustom';

const LoginForm = () => {
    
    const { navigateHome } = useNavigateCustom()
    const { setIsAuthenticated } = useAuth();
    const { register, handleSubmit, formState: { errors } } = useForm();
    const [apiError, setApiError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };


    const onSubmit = async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/login`, data);
            const { token } = response.data;
            localStorage.setItem('token', token);
            setIsAuthenticated(true);
            navigateHome();
        } catch (error) {
            console.error(error.response.data.message);
            // Mettre à jour l'état d'erreur lorsque l'API renvoie une erreur
            setApiError('Email ou mot de passe incorrect');
        }
    };

    return (
        <div className="login-form-container">
            <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                <div>

                    <label htmlFor="email"><FontAwesomeIcon style={{ color: "#1a9b4a" }} icon={faAt} /> Email</label>
                    <div style={{ position: 'relative' }}>
                        <input
                            id="email"
                            type="email"
                            placeholder='Entrez une adresse mail'
                            {...register('email', { required: 'Veuillez entrer votre email' })}
                        />
                    </div>


                </div>
                {errors.email && <p className=" text-right form-error">{errors.email.message}</p>}
                <div>
                    <label htmlFor="password"><FontAwesomeIcon style={{ color: "#1a9b4a" }} icon={faKey} /> Mot de passe </label>
                    <div style={{ position: 'relative' }}>
                        <input
                            id="password"
                            type={showPassword ? "text" : "password"}
                            placeholder='Entrez votre mot de passe'
                            {...register('password', {
                                required: 'Veuillez entrer votre mot de passe',
                            })}
                        />
                        <FontAwesomeIcon
                            icon={showPassword ? faEyeSlash : faEye}
                            onClick={togglePassword}
                            style={{ position: 'absolute', right: '10px', top: '15px', cursor: 'pointer' }}
                        />
                    </div>

                </div>
                {errors.password && <p className="form-error" >{errors.password.message}</p>}
                <div className='d-flex flex-column mt-4'>
                    <p className="no-account">Pas encore de compte ? <Link to="/signup">créez en un !</Link></p>
                    <p className="no-account"><Link to="/forgotten/password">Mot de passe oublié ?</Link></p>
                </div>

                <button className="button-red" type="submit">Se connecter</button>
                {apiError && <p className="form-error">{apiError}</p>}
            </form >
        </div >
    );
};

export default LoginForm;
