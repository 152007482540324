import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

import './style.css';
import { toast } from 'react-toastify';

const ManageUsers = () => {
  const [users, setUsers] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  useEffect(() => {
    getUsers();
  }, [page, searchTerm]);

  const getUsers = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/admin/users`, {
        params: {
          page,
          searchTerm,
        },
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });

      setUsers(response.data.users);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    setSearchTerm(event.target.value);
  };

  const handleToggleUser = async (userId, isActive) => {
    try {
      await axios.patch(`${process.env.REACT_APP_API_URL}/api/admin/user/${userId}`, {
        isActive: !isActive,
      }, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        }
      });
      getUsers();

      toast.success(`Utilisateur ${isActive ? 'Désactivé' : 'Activé'}`, {
        position: toast.POSITION.TOP_CENTER
      });

    } catch (error) {
      console.error('Error toggling user activation:', error);
    }
  };

  const startConversation = async (userId) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/messages/direct-conversation`,
        { targetUserId: userId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const conversationId = response.data.conversation._id;
      navigate(`/messages/direct/${conversationId}`);
    } catch (error) {
      console.error('Error starting conversation:', error);
    }
  };

  return (
    <div className="profile-container profile-admin-users">
      <input
        type="text"
        value={searchTerm}
        onChange={handleSearch}
        placeholder="Rechercher par email, nom ou prénom"
      />
      <ul>
        {users.map((user) => (
          <li key={user._id}>
            {`${user.firstName} ${user.lastName} / ${user.email}`}
            <div>
              <button className={`${user.isActive && 'disable'}`} onClick={() => handleToggleUser(user._id, user.isActive)}>
                {user.isActive ? 'Désactiver' : 'Activer'}
              </button>
              <button onClick={() => startConversation(user._id)}>
                Lancer une conversation
              </button>
            </div>

          </li>
        ))}
      </ul>
      <div>
        {page > 1 && <button onClick={() => setPage(page - 1)}>Précédent</button>}
        {page < totalPages && <button onClick={() => setPage(page + 1)}>Suivant</button>}
      </div>
    </div>
  );
};

export default ManageUsers;