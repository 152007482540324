import React, { useEffect, useState } from 'react'
import Link2 from '../Link2'
import axios from 'axios';
import { formatDate } from '../../utils/data';

function LatestAds({
    iframe,
}) {

    const [latestAds, setLatestAds] = useState([]);

    useEffect(() => {
        if (!latestAds.length) {

            fetchLatestAds();
        }

    }, []);

    const fetchLatestAds = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/adverts`, {
                params: { page: 1, limit: 4 },
            });
            setLatestAds(response.data.ads);
        } catch (error) {
            console.error('Error fetching latest ads:', error);
        }
    };

    return (
        <>
            <p className="home-title" style={{marginTop: iframe ? "15px" : "4rem"}}>Dernières <span className="text-boz-red">Annonces</span> publiées</p>
            <div className="latest-advert-container">
                {latestAds.map((ad) => (
                    <Link2 className="latest-advert mb-3" key={ad.id} to={`/ad/${ad._id}`}>
                        <div key={ad._id} style={{ position: "relative" }} >
                            {ad.user.professional && <span className="badge-pro">Pro</span>}
                            <div className="latest-advert-container-image">
                                <img className="latest-advert-image" src={`${process.env.REACT_APP_API_URL}/${ad.images[0]}`} alt={ad.productName} />

                            </div>
                            <div >
                                <h3 className="latest-advert-product">{ad.productName}</h3>
                                <p className="latest-advert-by mb-1">Par <span className="latest-advert-by-name">{`${ad.user.firstName}`}</span></p>
                                <p className="latest-advert-at">le <span className="latest-advert-at-date">{formatDate(ad.createdAt)}</span></p>
                            </div>
                        </div>
                    </Link2>
                ))}
            </div>
        </>
    )
}

export default LatestAds