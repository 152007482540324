import React from 'react';
import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './contexts/AuthContext';
import { Helmet } from 'react-helmet';
import { BrowserRouter as Router } from 'react-router-dom';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import LatestAds from './components/LatestAds';

const rootElement = document.getElementById('root');
const detachedHeaderRootElement = document.getElementById('jvmp-header');
const detachedSearchRootElement = document.getElementById('jvmp-search');
const detachedLastAdsRootElement = document.getElementById('jvmp-lastAds');

if (rootElement) {
  const root = ReactDOM.createRoot(document.getElementById('root'));
  root.render(
    <React.StrictMode>
      <AuthProvider>
        <Helmet>
          <title>Je vends mon potager</title>
        </Helmet>
        <Router>
          <App />
        </Router>

      </AuthProvider>
    </React.StrictMode>
  );
}
if (detachedSearchRootElement) {
  const detachedSearchRoot = ReactDOM.createRoot(document.getElementById('jvmp-search'));
  detachedSearchRoot.render(
    <AuthProvider>
      <Router>
        <div className="main">
          <div className="home-container">
            <SearchBar />
          </div>
        </div>
      </Router>
    </AuthProvider>
  )
}

if (detachedHeaderRootElement) {
  const detachedHeaderRoot = ReactDOM.createRoot(document.getElementById('jvmp-header'));
  detachedHeaderRoot.render(
    <AuthProvider>
      <Router>
        <Header />
      </Router>
    </AuthProvider>
  )
}

if (detachedLastAdsRootElement) {
  const detachedLastAdsRoot = ReactDOM.createRoot(document.getElementById('jvmp-lastAds'));
  detachedLastAdsRoot.render(
    <AuthProvider>
      <Router>
        <div className="main">
          <div className="home-container">
            <LatestAds />
          </div>
        </div>
      </Router>
    </AuthProvider>
  )
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
