import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';


import './style.css';
import { faAt, faKey, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import useNavigateCustom from '../../hooks/useNavigateCustom';

const ForgottenPassword = () => {
    
    const { navigateHome } = useNavigateCustom();
    const { setIsAuthenticated } = useAuth();
    const { register, handleSubmit, formState: { errors }, getValues } = useForm();
    const [apiError, setApiError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const { token } = useParams();

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const onSubmit = async (data) => {
        try {
            if (!token) {
                const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/forgot-password`, data);
                const { token } = response.data;
                localStorage.setItem('token', token);
                setIsAuthenticated(true);
                toast.success(`Un lien vous a été envoyé par mail`, {
                    position: toast.POSITION.TOP_CENTER
                });
                setTimeout(() => {
                    navigateHome();
                }, 1000);
                return
            }
            delete data.passwordConfirm;
            data.token = token;
            await axios.post(`${process.env.REACT_APP_API_URL}/api/users/reset-password`, data);
            toast.success(`Mot de passe modifié avec succès`, {
                position: toast.POSITION.TOP_CENTER
            });
            setTimeout(() => {
                navigateHome();
            }, 1000);
        } catch (error) {
            console.error(error.response.data.message);
            // Mettre à jour l'état d'erreur lorsque l'API renvoie une erreur
            setApiError(error.response.data.message);
        }
    };

    return (
        <div className="login-form-container">
            {!token ?
                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                    <div>

                        <label htmlFor="email"> <FontAwesomeIcon style={{ color: "#1a9b4a" }} icon={faAt} /> Email</label>
                        <input
                            id="email"
                            type="email"
                            placeholder='Entrez une adresse mail'
                            {...register('email', { required: 'Veuillez entrer votre email' })}
                        />
                        {errors.email && <p>{errors.email.message}</p>}
                    </div>
                    <p className="no-account">Cliquez sur réinitialiser pour recevoir un mail de confirmation</p>

                    <button className="button-red" type="submit">Réinitialiser</button>
                    {apiError && <p className="form-error">{apiError}</p>}
                </form>

                :
                <form className="login-form" onSubmit={handleSubmit(onSubmit)}>
                    <div>
                        <label htmlFor="password">Nouveau mot de passe  <span className='required-star'>*</span></label>
                        <div style={{ position: 'relative' }}>
                            <input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                {...register('password', {
                                    required: 'Veuillez entrer votre mot de passe',
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-+={[}\]|\\:;"'<,>.?\/])[\w~`!@#$%^&*()\-+={[}\]|\\:;"'<,>.?\/]{6,}$/,
                                        message: "Le mot de passe doit comprendre au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial ( ~`!@#$ %^&*()_-+={[}]|\\:; »'<,>.?/), et doit comporter au moins 6 caractères."
                                    }
                                })}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                onClick={togglePassword}
                                style={{ position: 'absolute', right: '10px', top: '15px', cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                    {errors.password && <p className="form-error">{errors.password.message}</p>}
                    <div>
                        <label htmlFor="passwordConfirm">Confirmer le mot de passe <span className='required-star'>*</span></label>
                        <div style={{ position: 'relative' }}>
                            <input
                                id="passwordConfirm"
                                type={showPassword ? "text" : "password"}
                                {...register('passwordConfirm', {
                                    required: 'Veuillez confirmer votre mot de passe',
                                    validate: value => value === getValues().password || 'Les mots de passe ne correspondent pas.'
                                })}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                onClick={togglePassword}
                                style={{ position: 'absolute', right: '10px', top: '15px', cursor: 'pointer' }}
                            />
                        </div>
                    </div>
                    {errors.passwordConfirm && <p className="form-error">{errors.passwordConfirm.message}</p>}


                    <button className="button-red" type="submit">Réinitialiser</button>
                    {apiError && <p className="form-error">{apiError}</p>}
                </form>
            }

        </div>
    );
};

export default ForgottenPassword;
