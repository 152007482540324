import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import './style.css';
import { Accordion } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArchive, faEye, faFlag, faTimes } from '@fortawesome/free-solid-svg-icons';

const ReportedAds = () => {

  const [reportedAds, setReportedAds] = useState([]);
  const navigate = useNavigate();

  const token = localStorage.getItem('token');

  const fetchReportedAds = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reports/reports`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        setReportedAds(data);
      } else {
        console.error('Erreur lors de la récupération des annonces en attente:', data.error);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des annonces en attente:', error);
    }
  };

  const handleArchiveClick = async (report) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/advert/${report.ad._id}`, { status: "deleted", deleteReason: "" }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      await axios.delete(`${process.env.REACT_APP_API_URL}/api/reports/${report._id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`Annonce supprimé avec succès`, {
        position: toast.POSITION.TOP_CENTER
      });
      fetchReportedAds();

    } catch (error) {
      toast.error('Une erreur est survenue lors de l\'archivage de l\'annonce', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  };

  const handleDeleteClick = async (id) => {
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/reports/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`Signalement supprimé avec succès`, {
        position: toast.POSITION.TOP_CENTER
      });
      fetchReportedAds();

    } catch (error) {
      toast.error('Une erreur est survenue lors de l\'archivage de l\'annonce', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  useEffect(() => {
    fetchReportedAds();
  }, []);

  return (
    <div className='admin-report-annoncement'>
      {reportedAds?.length ? (

        <ul>
          {reportedAds.map((report) => (
            <li key={report._id}>
              {report.ad.productName} par {report.reporter.firstName} {report.reporter.lastName}
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEye} title="Voir l'annonce" onClick={() => navigate(`/ad/${report.ad._id}`)} aria-label="Voir l'annonce" />
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faFlag} title="Voir la raison" onClick={() => alert(report.reason)} aria-label="Voir la raison" />
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faArchive} title={"Supprimer l'annonce"} onClick={() => handleArchiveClick(report)} />
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} title="Supprimer le signalement" className="delete" onClick={() => handleDeleteClick(report._id)} aria-label="Supprimer le signalement" />
            </li>


          ))}
        </ul>
      ) :
        <p>-</p>
      }
    </div>
  );
}

export default ReportedAds;
