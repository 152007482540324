import axios from 'axios';
import React, { useEffect, useMemo } from 'react'
import { useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import AdCard from '../utils/AdCard';
import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass, faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';

import './style.css';
import { useNavigate } from 'react-router-dom';
import useNavigateCustom from '../../hooks/useNavigateCustom';

function Alerts({
    searches,
    setSearches,
}) {

    const [user, setUser] = useState({});
    const { isAuthenticated } = useAuth();
    const navigate = useNavigate();
    const { navigateHome } = useNavigateCustom();

    useEffect(() => {

        const fetchUserDataAndUpdateAlerts = async () => {
            const token = localStorage.getItem('token');
            try {
                // Fetch user data
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/user`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);

                // Update hasNewAlert to false
                await axios.put(`${process.env.REACT_APP_API_URL}/api/users/update`, {
                    hasNewAlert: false,
                }, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json',
                    },
                });
            } catch (error) {
                console.error('Error fetching user data or updating alerts:', error);
            }
        };
        if (isAuthenticated) {
            fetchUserDataAndUpdateAlerts();
        }

    }, [isAuthenticated]);
    useEffect(() => {
        const fetchUserSearches = async () => {
            const token = localStorage.getItem('token');
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setSearches(response.data);
            } catch (error) {
                console.error('Erreur lors de la récupération des recherches de l\'utilisateur:', error);
            }
        };

        if (isAuthenticated) {
            fetchUserSearches();
        }

    }, [isAuthenticated]);

    const deleteSearch = async (searchId) => {
        const token = localStorage.getItem('token');
        try {
            await axios.delete(`${process.env.REACT_APP_API_URL}/api/search/${searchId}`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            setSearches(searches.filter(search => search._id !== searchId));
            toast.success('Recherche supprimée avec succès', {
                position: toast.POSITION.TOP_CENTER
            });
        } catch (error) {
            console.error('Erreur lors de la suppression de la recherche:', error);
        }
    };


    const traslateMainType = (mainType) => {
        if (mainType === 'offer') {
            return 'Offre'
        }
        if (mainType === 'asked') {
            return 'Demande'
        }
    };

    const traslateAdType = (type) => {
        if (type === 'trade') {
            return 'Troc'
        }
        if (type === 'donation') {
            return 'Don'
        }
        if (type === 'sell') {
            return 'Vente'
        }

    };

    const getSearchOptionText = ({ query }) => {
        let str = '';
        const { mainType, productName, city, postalCode, type, coordinates, distance } = query;

        if (mainType) {
            str += traslateMainType(mainType) + '; ';
        }
        if (productName) {
            str += productName + '; ';
        }
        if (city) {
            str += city + '; ';
        }
        if (postalCode) {
            str += postalCode + '; ';
        }
        if (type) {
            str += traslateAdType(type) + '; ';
        }

        if (distance) {
            str += 'Distance ' + distance + ' km' + '; ';
        }


        return str;
    }

    const handleClickSearch = async ({ _id, hasNew, query }) => {

        if (hasNew) {

            const token = localStorage.getItem('token');

            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/search/${_id}`, { hasNew: false }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });
            const newSeaches = searches.map(search => response?.data?._id === search._id ? response.data : search);
            if (newSeaches) {
                setSearches(newSeaches);
            }
        }
        const url = `/annonces?mainType=${query.mainType}&productName=${query.productName}&city=${query.city}&postalCode=${query.postalCode}&type=${query.type}&coordinates=${query.coordinates}&distance=${query.distance}`;
        navigate(url);
    }

    const onChangeAlertState = (e) => {
        e.stopPropagation()
    }

    const handleDeleteSearch = (e, search) => {
        e.stopPropagation();
        deleteSearch(search._id)
    }

    const handelChangeNotificationEmail = async (search) => {
        const token = localStorage.getItem('token');
        try {
            // Récupère l'état actuel de la notification par e-mail et l'inverse
            const newEmailNotificationState = !search?.notifications?.email;

            // Préparation de l'objet de mise à jour
            const updates = {
                notifications: {
                    ...search.notifications,
                    email: newEmailNotificationState,
                },
            };

            // Envoi de la requête PATCH à l'API pour mettre à jour l'état de la notification
            const response = await axios.patch(`${process.env.REACT_APP_API_URL}/api/search/${search._id}/notifications`, updates, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                },
            });

            // Mise à jour de l'état local après la mise à jour réussie de la notification
            setSearches(searches.map(s => s._id === search._id ? { ...s, notifications: response.data.notifications } : s));
            toast.success('Notification mise à jour avec succès', {
                position: toast.POSITION.TOP_CENTER,
            });
        } catch (error) {
            console.error('Erreur lors de la mise à jour de la notification par e-mail:', error);
            toast.error('Erreur lors de la mise à jour de la notification', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };


    return (
        <>
            <div className="home-title">Vos recherches <span className="text-boz">enregistrées</span></div>


            {
                searches.length ?
                    <div className="search-container">

                        {searches.map((search) => (
                            <div key={search._id} onClick={() => handleClickSearch(search)} className="search-item" >
                                <div className='search-item-title-container'>
                                    <div className="search-item-title">{search.query.productName}</div>
                                    {search.hasNew && <div className='search-count'></div>}
                                </div>
                                <div className="search-item-words">{getSearchOptionText(search)}</div>
                                <div onClick={onChangeAlertState} className="search-item-notification-container">
                                    <div>Activer la notification par mail</div>
                                    <div>
                                        <label class="switch">
                                            <input type="checkbox" checked={search?.notifications?.email} onChange={() => handelChangeNotificationEmail(search)} />
                                            <span class="slider round"></span>
                                        </label>
                                    </div>
                                </div>
                                <p className="search-item-notification-action" onClick={(e) => handleDeleteSearch(e, search)}><FontAwesomeIcon icon={faTrash} className="me-2" />Supprimer</p>
                            </div>
                        ))
                        }
                    </div>
                    :

                    <div className="search-no-item">
                        <p className="search-no-item-title">Vous n'avez pas encore de recherches enregistrées ?</p>
                        <button className="button-green" onClick={() => navigateHome()}>Lancer une recherche</button>
                    </div>
            }


        </>
    )
}

export default Alerts