import React, { useState, useEffect } from 'react';
import AddAdminForm from '../../components/AddAdminForm';

import './style.css'

const ManageAdmins = () => {
  const [admins, setAdmins] = useState([]);

  const token = localStorage.getItem('token');

  useEffect(() => {
    // Charger la liste des administrateurs lors du montage du composant
    fetchAdmins();
  }, []);

  const fetchAdmins = async () => {
    // Récupérer la liste des administrateurs à partir de l'API et mettre à jour l'état
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    const adminsData = await response.json();
    setAdmins(adminsData);
  };

  const handleAddAdmin = async (newAdminData) => {
    // Envoyer une requête POST à l'API pour créer un nouvel administrateur
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/user`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newAdminData),
    });

    if (response.ok) {
      const newAdmin = await response.json();

      // Mettre à jour l'état avec le nouvel administrateur
      setAdmins((prevState) => [...prevState, newAdmin]);
    } else {
      // Gérer les erreurs de la requête
      console.error('Erreur lors de la création du nouvel administrateur.');
    }
  };

  return (
    <div className="profile-container profile-admin-admins">
      <p>Liste des administrateurs</p>
      <ul>
        {admins.map((admin) => (
          <li key={admin._id}>{admin.email}</li>
        ))}
      </ul>

      <p>Ajouter des administrateurs</p>
      <div>
        <AddAdminForm onSubmit={handleAddAdmin} />
      </div>
    </div>
  );
};

export default ManageAdmins;