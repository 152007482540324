import React from 'react'
import { Link, useLocation } from 'react-router-dom';

const Link2 = ({ children, to, ...props }) => {
    const location = useLocation();

    if (location.pathname.includes('/iframe')) {
        return <a href={to} target='_parent' {...props}>{children}</a>
    }
    return <Link to={to} {...props}>{children}</Link>
}

export default Link2