import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import Modal from 'react-modal';
import { useAuth } from '../../contexts/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArchive, faBars, faLeftLong, faMagnifyingGlass, faPaperPlane, faRightLong, faTimes } from "@fortawesome/free-solid-svg-icons";
import { toast } from 'react-toastify';
import { slide as Menu } from 'react-burger-menu';
import './style.css';


const ConversationList = ({ children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);

    };
  }, []);

  if (isMobile) {
    return (<Menu isOpen={isOpen} onStateChange={(state) => setIsOpen(state.isOpen)} left customBurgerIcon={<FontAwesomeIcon size='xs' icon={isOpen ? faLeftLong : faRightLong} />} >
      {children}
    </Menu>)
  }
  return <div style={{ width: "20%" }}>
    {children}
  </div>
}

const MessagePage = () => {
  const { adId, conversationId } = useParams();
  const [ad, setAd] = useState(null);

  const [messages, setMessages] = useState([]);
  const [conversation, setConversation] = useState(null);
  const [conversations, setConversations] = useState([]);
  const [messageInput, setMessageInput] = useState('');
  const [hoveredMessageId, setHoveredMessageId] = useState(null);
  const [modalArchiveIsOpen, setModalArchiveIsOpen] = useState(false);
  const [archiveConversation, setArchiveConversation] = useState(null);
  const [hoveredConversationId, setHoveredConversationId] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const navigate = useNavigate();

  const { user: me } = useAuth();

  const token = localStorage.getItem('token');

  const receiver = conversation?.participants?.find(p => p._id !== me.id) || ad?.user;

  const fetchConversations = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/messages/conversations`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //setConversations(response.data.conversations);
      setConversations(response.data.conversations.map(c => c._id === conversationId ? { ...c, unreadMessages: 0 } : c));

    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

  useEffect(() => {
    fetchConversations();

    const intervalId = setInterval(() => {
      fetchConversations();
    }, 10000); // Fetch every 10 seconds

    // Cleanup interval on unmount
    return () => clearInterval(intervalId);
  }, [conversationId]);

  useEffect(() => {
    if (conversations.length > 0 && !conversation && !adId && !conversationId) {
      setConversation(conversations[0]);
      navigate(conversations[0].ad ? `/messages/${conversations[0].ad._id}/${conversations[0]._id}` : `/messages/direct/${conversations[0]._id}`);
    }
  }, [conversations, conversation, navigate, adId, conversationId]);

  useEffect(() => {

    if (adId) {

      const fetchAd = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/adverts/${adId}`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setAd(response.data.ad);
        } catch (error) {
          console.error('Error fetching ad:', error);
        }
      };

      fetchAd();
      return;
    }
    setAd(null);
  }, [adId]);

  useEffect(() => {
    const fetchConversation = async () => {
      try {
        const response = await axios.get(

          `${process.env.REACT_APP_API_URL}/api/messages/conversations/${conversationId}`,
          {

            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        setConversation(response.data);
      } catch (error) {
        console.error('Error fetching conversation:', error);
      }
    };

    fetchConversation();
  }, [adId, conversationId, conversations]);

  useEffect(() => {
    const fetchMessages = async (conversationId) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/messages/conversations/${conversationId}/messages`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setMessages(response.data.messages);
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    };

    if (conversation) {
      fetchMessages(conversation._id);
    }
  }, [conversation]);

  const sendMessage = async () => {
    let receiverId = null;
    if (conversation) {
      receiverId = conversation.participants.find(p => p !== me.id)
    }
    else {
      receiverId = ad.user._id
    }
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/messages/create`, {
        adId,
        conversationId,
        receiverId,
        text: messageInput,
      },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      setMessageInput('');
      setMessages([...messages, response.data.message]);
      fetchConversations();
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleConversationClick = (conversation) => {
    setConversation(conversation);
  };

  const getConversationUserName = (conversation) => {
    const participant = conversation?.participants?.find(participant => participant._id !== me.id);
    if (participant) {
      return `${participant.firstName}`;
    }
    return 'Conversation inconnue';
  }

  const isAdminConversation = (conversation) => {
    const participant = conversation?.participants?.find(participant => participant._id !== me.id);
    if (participant) {
      return participant.role === 0;
    }
    return false
  }

  const handleArchiveRequest = (conversation) => {
    setArchiveConversation(conversation);
    setModalArchiveIsOpen(true);
  };

  const handleArchiveConfirm = async () => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/messages/conversation/${archiveConversation._id}/archive`, {}, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      fetchConversations();  // To refresh the conversations list
      setModalArchiveIsOpen(false);
      setArchiveConversation(null);

      // Redirect to the first conversation if there is one
      if (conversations.length > 0) {
        navigate(conversations[0].ad ? `/messages/${conversations[0].ad._id}/${conversations[0]._id}` : `/messages/direct/${conversations[0]._id}`);
      }

      // Show toast notification
      toast.success("La conversation a été archivée avec succès !");
    } catch (error) {
      console.error('Error archiving conversation:', error);

      // Show toast notification for error
      toast.error("Une erreur est survenue lors de l'archivage de la conversation.");
    }
  };


  const initials = (user) => `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`.toUpperCase();

  if (!me.isActive) {
    return <div className="add-ad-form-suspended mt-4">Votre compte est suspendu, vous ne pouvez pas accéder à la messagerie</div>

  }

  return (
    <div className="message-page">
      <ConversationList>
        <div className="conversations-menu">
          <ul>
            {conversations.map((conversation) => (
              <li
                key={conversation._id}
                className={`${(conversation._id === conversationId) ? 'selected' : ''} ${isAdminConversation(conversation) ? 'admin' : ''}`}
                onClick={() => navigate(conversation.ad ? `/messages/${conversation.ad._id}/${conversation._id}` : `/messages/direct/${conversation._id}`)}
                onMouseEnter={() => setHoveredConversationId(conversation._id)}
                onMouseLeave={() => setHoveredConversationId(null)}
              >
                {isAdminConversation(conversation) && <span className="admin-badge">ADMIN</span>}
                {conversation.ad ?
                  <Link to={`/messages/${conversation.ad._id}/${conversation._id}`}>
                    {conversation.ad.productName} | {getConversationUserName(conversation)}
                  </Link>
                  :
                  <Link to={`/messages/direct/${conversation._id}`}>
                    {getConversationUserName(conversation)}
                  </Link>
                }
                {conversation.unreadMessages > 0 &&
                  <div className="unread-messages-icon">
                    {conversation.unreadMessages}
                  </div>
                }
                {hoveredConversationId === conversation._id &&
                  <FontAwesomeIcon
                    icon={faTimes}
                    size="xs"
                    style={{ color: "#eb1b23", cursor: "pointer", position: "absolute", right: 0, bottom: 0, height: "15px" }}
                    onClick={(e) => { e.stopPropagation(); handleArchiveRequest(conversation); }}
                  />
                }
              </li>
            ))}

          </ul>
        </div>
      </ConversationList>

      <div className="conversation-content">
        {adId || conversationId ? (
          <>
            {receiver && (
              <div className="messages-title">
                <div className="messages-profile-image-container">
                  {receiver.profileImage ? (
                    <img className="messages-profile-image" src={`${process.env.REACT_APP_API_URL}${receiver.profileImage}`} alt="User profile" />
                  ) : (
                    <div className="messages-profile-placeholder">{initials(receiver)}</div>
                  )}
                </div>
                {ad && <><p className="messages-title-name">{ad.productName}</p>
                  <Link to={`/ad/${ad._id}`} >
                    <button className="view" style={{ margin: '0.5rem 0' }}>Voir l'annonce <span className="arrow"></span></button>
                  </Link></>}

                <p className="messages-title-user">{`${receiver.firstName}`}</p>
              </div>
            )}
            <div className="messages-container">
              {messages.length === 0 ? (
                <></>
              ) : (
                conversation &&
                messages.map((message) => (
                  <div
                    key={message._id}
                    className={`message ${message.sender && message.sender._id !== me.id
                      ? "left"
                      : "right"
                      }`}
                    onMouseEnter={() => setHoveredMessageId(message._id)}
                    onMouseLeave={() => setHoveredMessageId(null)}
                  >
                    <div className="message-text">{message.text}</div>
                    <div
                      className="message-date"
                      style={{
                        visibility: hoveredMessageId === message._id ? 'visible' : 'hidden'
                      }}
                    >
                      {new Date(message.sentAt).toLocaleString()}
                    </div>
                  </div>
                ))
              )}
            </div>
            <div className="message-input-container">
              <textarea
                value={messageInput}
                onChange={(e) => setMessageInput(e.target.value)}
                rows={4}
                placeholder="Écrivez votre message..."
              />
              <FontAwesomeIcon icon={faPaperPlane} size="2xl" style={{ color: "#eb1b23", cursor: "pointer" }} onClick={sendMessage} />
            </div>
          </>
        ) : (
          <div className="message-empty" >
            <h1><span className="text-boz"><FontAwesomeIcon icon={faMagnifyingGlass} size="xl" style={{ color: "#ffffff", }} /> Aucun message</span></h1>
          </div>
        )}
      </div>
      <Modal
        isOpen={modalArchiveIsOpen}
        onRequestClose={() => setModalArchiveIsOpen(false)}
        contentLabel="Confirmation d'archivage"
        className="modal-container"
      >
        <h2 className="modal-title">Archiver la conversation</h2>

        <p>Êtes-vous sûr de vouloir archiver cette conversation ?</p>
        <div className='modal-actions'>
          <button className="button-red-outline" onClick={() => setModalArchiveIsOpen(false)}>
            Retour
          </button>
          <button className="button-green" onClick={handleArchiveConfirm}>
            Confirmer
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default MessagePage;