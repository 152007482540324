// HomePage.js
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Adsense } from '@ctrl/react-adsense';
import SearchBar from '../SearchBar';
import LatestAds from '../LatestAds';

import './style.css'


const HomePage = () => {

  const [latestAds, setLatestAds] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);

    };
  }, []);
  useEffect(() => {
    if (!latestAds.length) {

      fetchLatestAds();
    }

  }, []);

  const fetchLatestAds = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/adverts`, {
        params: { page: 1, limit: 4 },
      });
      setLatestAds(response.data.ads);
    } catch (error) {
      console.error('Error fetching latest ads:', error);
    }
  };

  return (
    <div className="home-container">
      <div className="home-title">Quoi de <span className="text-boz">bon</span> dans les <span className="text-boz">potagers</span> voisins aujourd'hui ?</div>
      <SearchBar />
      <Adsense
        client="ca-pub-5061084407043738"
        slot="8502427779"
        style={{ display: 'block', height: '110px', marginTop: '2rem', textAlign: 'center' }}
        format="auto"
        responsive="true"
      />
      {(!isMobile || !location.pathname.includes('/iframe')) &&
        <LatestAds />
      }

    </div>
  );
};

export default HomePage;
