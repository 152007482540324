import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';

import './style.css';
import { toast } from 'react-toastify';
import { useAuth } from '../../contexts/AuthContext';
import useNavigateCustom from '../../hooks/useNavigateCustom';

const ProfileEdit = () => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [profileImage, setProfileImage] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { setIsAuthenticated } = useAuth();
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const { navigateHome } = useNavigateCustom();

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigateHome();
  };

  // Fetch user data
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Replace with the appropriate API URL and headers
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user data:', error);
        setError('An error occurred while fetching user data.');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    // Validate file size (let's say we want to limit it to 1MB)
    /*if (file.size > 1000000) {
      alert('Fichier trop volumineux!');
      return;
    }*/

    // Validate file dimensions
    const img = new Image();
    /*img.onload = function () {
      if (this.width > 2000 || this.height > 2000) {
        alert('Les dimensions de l\'image sont trop grandes!');
        return;
      }
    }*/
    img.src = URL.createObjectURL(file);

    setProfileImage(file);
  };

  const handleDeleteAccount = async () => {
    setModalIsOpen(false);
    try {
      await axios.delete(`${process.env.REACT_APP_API_URL}/api/users/delete`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.success(`Compte supprimé avec succès`, {
        position: toast.POSITION.TOP_CENTER
      });
      // Supprimez le token du local storage et redirigez l'utilisateur vers la page de connexion
      localStorage.removeItem('token');
      // Redirection à la page de connexion
      window.location.href = "/login";
    } catch (error) {
      console.error('Error deleting account:', error);
      setError('An error occurred while deleting the account.');
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    Object.keys(user).forEach((key) => {
      if (key !== 'alertAds') {
        if (key !== 'address') {
          formData.append(key, user[key]);
        } else {
          Object.keys(user.address).forEach((subKey) => {
            formData.append(`address[${subKey}]`, user.address[subKey]);
          });
        }
      }

    });

    if (newPassword) {
      formData.append('password', newPassword);
    }

    if (profileImage) {
      formData.append('profileImage', profileImage);
    }

    try {
      // Replace with the appropriate API URL and headers
      await axios.put(`${process.env.REACT_APP_API_URL}/api/users/update`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      // Redirect to the user's profile page
      // Replace this line with your desired navigation method
      toast.success(`Mise à jour avec succès`, {
        position: toast.POSITION.TOP_CENTER
      });
      navigateHome();
    } catch (error) {
      console.error('Error updating user information:', error);
      setError('An error occurred while updating user information.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div className="profile-container profile-edit">
      <form onSubmit={handleSubmit}>
        <label>
          <span className="profil-label-name">Prénom</span>
          <input
            type="text"
            value={user.firstName}
            onChange={(e) => setUser({ ...user, firstName: e.target.value })}
          />
        </label>
        <br />
        <label>
          <span className="profil-label-name">Nom</span>
          <input
            type="text"
            value={user.lastName}
            onChange={(e) => setUser({ ...user, lastName: e.target.value })}
          />
        </label>
        <br />
        <label>
          <span className="profil-label-name">Email</span>
          <input
            type="email"
            value={user.email}
            onChange={(e) => setUser({ ...user, email: e.target.value })}
          />
        </label>
        <br />

        <label className="profile-image-label">
          <span className="profil-label-name">Photo de profil</span>
          <div className="profile-image-input-container">
            <div style={{ marginLeft: "auto", display: "flex" }} >
              {user.profileImage && <img src={`${process.env.REACT_APP_API_URL}${user.profileImage}`} alt="Profile" className="profile-image" />}
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
              />
            </div>
          </div>
        </label>
        <br />
        <label className="profile-image-label">
          <span className="profil-label-name">Numero de téléphone</span>
          <input
            type="text"
            value={user.phone}
            onChange={(e) => setUser({ ...user, phone: e.target.value })}
          />
        </label>
        <br />
        <label>
          <span className="profil-label-name">Ville</span>
          <input
            type="text"
            value={user.address.city}
            onChange={(e) => setUser({ ...user, address: { ...user.address, city: e.target.value } })}
          />
        </label>
        <br />

        <label>
          <span className="profil-label-name">Code postal</span>
          <input
            type="text"
            value={user.address.postalCode}
            onChange={(e) => setUser({ ...user, address: { ...user.address, postalCode: e.target.value } })}
          />
        </label>
        <br />
        {
          user && user.professional && <>
            <label>
              <span className="profil-label-name">Entreprise</span>
              <input
                type="text"
                value={user.companyName}
                onChange={(e) => setUser({ ...user, companyName: e.target.value })}
              />
            </label>
            <label>
              <span className="profil-label-name">Siret</span>
              <input
                type="text"
                value={user.companySiret}
                onChange={(e) => setUser({ ...user, companySiret: e.target.value })}
              />
            </label>
          </>
        }

        <label>
          <span className="profil-label-name">Choisir un nouveau de passe</span>
          <div style={{ position: 'relative' }}>
            <input
              style={{ paddingRight: '30px' }}
              type={showPassword ? "text" : "password"}
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
            <FontAwesomeIcon
              style={{ position: 'absolute', right: '5px', top: '50%', transform: 'translateY(-50%)', cursor: 'pointer' }} // Positionner l'icône
              icon={showPassword ? faEyeSlash : faEye}
              onClick={() => setShowPassword(!showPassword)}
            />
          </div>
        </label>
        <br />
        <label>
          <span className="profil-label-name">Autoriser à afficher mon numéro de téléphone dans mes annonces ?</span>
          <input
            type="checkbox"
            checked={user.showPhoneNumber}
            placeholder="Sesissez votre nouveau mot de passe"
            onChange={(e) => setUser({ ...user, showPhoneNumber: e.target.checked })}
          />
        </label>
        <br />
        <button type="submit">Enregistrer</button>

      </form>
      <div className="profil-action-button">
        <button className="button-red mt-4" style={{ fontSize: '12px' }} onClick={handleLogout}>
          Se déconnecter
        </button>
        <button className="button-red mt-4" style={{ fontSize: '12px' }} onClick={() => setModalIsOpen(true)}>
          Supprimer mon compte
        </button>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Modal de confirmation"
        className="modal-container"
      >
        <h2>Êtes-vous sûr de vouloir supprimer votre compte?</h2>
        <div className="d-flex mt-4 justify-content-end w-100 justify-content-center flex-wrap">
          <button className="button-green me-2 mb-2" onClick={handleDeleteAccount}>Oui, supprimer</button>
          <button className="button-red mb-2" onClick={() => setModalIsOpen(false)}>Non, annuler</button>
        </div>
      </Modal>
    </div>
  );
};

export default ProfileEdit;