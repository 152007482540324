import React, { useState, useEffect, useRef, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { slide as Menu } from 'react-burger-menu';
import { useAuth } from '../../contexts/AuthContext';

import './Header.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCarrot, faMessage, faAdd, faBars, faBell, faPlus, faUser } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import useNavigateCustom from '../../hooks/useNavigateCustom';


const Link2 = ({ children, to, ...props }) => {
  const location = useLocation();

  if (location?.pathname.includes('/iframe')) {
    return <a href={to} target='_parent' {...props}>{children}</a>
  }
  return <Link to={to} {...props}>{children}</Link>
}

const Header = ({ iframe, searches, setSearches }) => {

  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [user, setUser] = useState({});

  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { navigateHome } = useNavigateCustom();
  const location = useLocation();
  const menuRef = useRef();


  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);


  const handleClickOutside = event => {
    if (menuRef.current && !menuRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const closeMenu = () => setIsOpen(false);
  const handleStateChange = (state) => setIsOpen(state.isOpen);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    document.addEventListener('click', handleClickOutside);
    return () => {
      window.removeEventListener('resize', handleResize);
      document.removeEventListener('click', handleClickOutside);

    };
  }, []);

  useEffect(() => {


    const fetchUserData = async () => {
      const token = localStorage.getItem('token');
      try {
        // Replace with the appropriate API URL and headers
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/users/user`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);


      }
    };
    if (isAuthenticated) {
      fetchUserData();
    }

  }, [isAuthenticated]);

  useEffect(() => {
    setIsOpen(false);
  }, [location.pathname]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    navigateHome();
  };

  const fetchUnreadMessageCount = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/messages/unread`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      setUnreadMessageCount(response.data.unreadMessageCount);
    } catch (error) {
      console.error('Error fetching unread message count:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchUnreadMessageCount();
      const intervalId = setInterval(() => {
        fetchUnreadMessageCount();
      }, 10000); // Fetch every 10 seconds

      // Cleanup interval on unmount
      return () => clearInterval(intervalId);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const fetchUserSearches = async () => {
      const token = localStorage.getItem('token');
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/search`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setSearches(response.data);
      } catch (error) {
        console.error('Erreur lors de la récupération des recherches de l\'utilisateur:', error);
      }
    };

    if (isAuthenticated) {
      fetchUserSearches();
    }

  }, [isAuthenticated]);

  const initials = user && `${user.firstName?.charAt(0)}${user.lastName?.charAt(0)} `.toUpperCase();

  const hasNewSearch = useMemo(() => searches.filter(s => s?.hasNew).length > 0, [searches])

  return (
    <>
      <header className="header">

        <div className="logo-container">
          {location.pathname.includes('/iframe') ? null : <div>
            <Link className="logo-container" to="https://jevendsmonpotager.fr">
              <img
                src="/images/logojvmp.png" // Remplacez par l'URL de votre logo
                alt="Logo"
                className="logo"
              />
              <span className="title">Je vends mon potager</span>
            </Link>
          </div>}
          <div className="middle-container">

          </div>
        </div>
        {isMobile ?
          (<></>)
          :
          <div className="right-container">
            <Link2 to="/ad/add" className="publish-ad-button">
              <FontAwesomeIcon style={{ marginRight: '0.5rem' }} size="xl" icon={faCarrot} />

              Publier une annonce
            </Link2>
            <Link2 to="/messages" className="header-messages-button">
              <div style={{ position: "relative" }}>
                <FontAwesomeIcon style={{ zIndex: 1, position: "relative" }} icon={faMessage} />
                {unreadMessageCount > 0 &&
                  <div className="unread-messages-badge">
                    <span>{unreadMessageCount}</span>
                  </div>
                }
              </div>
              <span className="">
                Messagerie
              </span>

            </Link2>
            {isAuthenticated &&
              <Link2 to="/alerts" className="header-messages-button">
                <div style={{ position: "relative" }}>
                  <FontAwesomeIcon style={{ zIndex: 1, position: "relative" }} icon={faBell} />
                  {hasNewSearch &&
                    <div className="unread-alerts-badge" />
                  }
                </div>
                <span className="">
                  Mes recherches
                </span>
              </Link2>
            }

            {isAuthenticated && (
              <Link2 className="header-profile-image-container" to={`/profil/info`}>
                {user.profileImage ? (
                  <img className="header-profile-image" src={`${process.env.REACT_APP_API_URL}${user.profileImage}`} alt="User profile" />
                ) : (
                  <div className="header-profile-placeholder">{initials}</div>
                )}
                {dropdownVisible && (
                  <div className="header-profile-dropdown">
                    <Link2 to={`/profil/info`} className="header-profile-button">
                      Mon compte
                    </Link2>
                    <Link2 onClick={handleLogout} className="header-logout-button">
                      Se déconnecter
                    </Link2>
                  </div>
                )}
              </Link2>
            )}
            {!isAuthenticated &&
              <div>
                <Link2 to="/login" className="header-profile-button">
                  <div style={{ position: "relative" }}>
                    <FontAwesomeIcon style={{ zIndex: 1, position: "relative" }} icon={faUser} />
                  </div>
                  <span className="">
                    Se connecter
                  </span>
                </Link2>
              </div>}

          </div>
        }

      </header>
      {isMobile && <div className={`mobile-menu-bar ${!iframe ? 'sticky' : ''}`}>
        <Link2 to="/ad/add" className="mobile-menu-bar-messages-button mobile-menu-bar-button">
          <FontAwesomeIcon className='mb-1' size="xl" icon={faCarrot} />
          <span>Publier</span>
        </Link2>
        <Link2 to="/messages" className="mobile-menu-bar-messages-button mobile-menu-bar-button">
          <div style={{ position: "relative" }}>
            <FontAwesomeIcon className='mb-1' style={{ zIndex: 1, position: "relative" }} size='xl' icon={faMessage} />
            {unreadMessageCount > 0 &&
              <div className="unread-messages-badge">
                <span>{unreadMessageCount}</span>
              </div>
            }
          </div>
          <span className="">
            Messagerie
          </span>

        </Link2>
        <Link2 to="/alerts" className="mobile-menu-bar-messages-button mobile-menu-bar-button">
          <div style={{ position: "relative" }}>
            <FontAwesomeIcon className='mb-1' style={{ zIndex: 1, position: "relative" }} size='xl' icon={faBell} />
            {hasNewSearch &&
              <div className="unread-alerts-badge" />
            }
          </div>
          <span className="">
            Mes recherches
          </span>
        </Link2>
        {isAuthenticated && (
          <Link2 className="header-profile-image-container mobile-menu-bar-button" to={`/profil/info`}>
            {user.profileImage ? (
              <img className="header-profile-image" src={`${process.env.REACT_APP_API_URL}${user.profileImage}`} alt="User profile" />
            ) : (
              <div className="header-profile-placeholder">{initials}</div>
            )}
            <span>
              Mon compte
            </span>
            {dropdownVisible && (
              <div className="header-profile-dropdown header-profile-dropdown-mobile">
                <Link2 to="/profil/info" className="header-profile-button">
                  Mon compte
                </Link2>
              </div>
            )}
          </Link2>
        )}
        {!isAuthenticated &&
          <div>
            <Link2 to="/login" className="mobile-menu-bar-messages-button mobile-menu-bar-button">
              <FontAwesomeIcon className='mb-1' size='xl' icon={faUser} />
              <span>
                Se connecter
              </span>
            </Link2>
          </div>}
      </div>}
    </>
  );
};

export default Header;