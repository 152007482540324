// src/components/PendingAds.js
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './style.css';
import { toast } from 'react-toastify';
import { Accordion } from 'react-bootstrap';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faEye, faTimes } from '@fortawesome/free-solid-svg-icons';

const PendingAds = () => {
  const [pendingAds, setPendingAds] = useState([]);
  const [modalDeleteIsOpen, setModalDeleteIsOpen] = useState(false);
  const [deleteReason, setDeleteReason] = useState('');
  const [selectAd, setSelectAd] = useState(null);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const fetchPendingAds = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/adverts/pending`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        setPendingAds(data);
      } else {
        console.error('Erreur lors de la récupération des annonces en attente:', data.error);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des annonces en attente:', error);
    }
  };

  useEffect(() => {
    fetchPendingAds();
  }, []);

  const handleAccept = async (adId) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/advert/${adId}`, { status: "accepted" }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success('Annonce validée avec succès', {
        position: toast.POSITION.TOP_CENTER
      });
      fetchPendingAds();

    } catch (error) {
      console.error('Erreur lors de l\'acceptation de l\'annonce:', error);
    }
  };

  const handleReject = async (adId) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/advert/${adId}`, { status: "refuse", deleteReason }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success('Annonce rejectée avec succès', {
        position: toast.POSITION.TOP_CENTER
      });
      fetchPendingAds();

    } catch (error) {
      console.error('Erreur lors du rejet de l\'annonce:', error);
    }
    finally {
      setModalDeleteIsOpen(false);
    }
  };

  const handleShowAdvert = (adId) => navigate(`/ad/${adId}`)

  return (
    <div className='admin-pending-annoncement'>
      {pendingAds?.length ? (
        <ul>
          {pendingAds.map((ad) => (
            <li key={ad._id}>
              {ad.productName} par {ad.user.firstName} {ad.user.lastName}
              <div>{ad.mainType === 'asked' ? 'Demande' : 'Offre'}</div>
              {ad.user.professional && <span className="badge-pro-simple">PRO</span>}
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faEye} onClick={() => handleShowAdvert(ad._id)} />
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faCheck} onClick={() => handleAccept(ad._id)} />
              <FontAwesomeIcon style={{ cursor: "pointer" }} icon={faTimes} className="refuse" onClick={() => {
                setSelectAd(ad._id)
                setModalDeleteIsOpen(true)
              }} />
            </li>

          ))}
        </ul>
      ) :
        <p>-</p>
      }
      <Modal
        isOpen={modalDeleteIsOpen}
        onRequestClose={() => setModalDeleteIsOpen(false)}
        contentLabel="Confirmation suppression"
        className="modal-container"
      >
        <h2 className="modal-title">Refuser l'annonce</h2>

        <p>Indiquez une raison</p>
        <textarea
          className="modal-textarea"
          value={deleteReason}
          onChange={(e) => setDeleteReason(e.target.value)}
        />
        <div className='modal-actions'>
          <button className="button-red-outline" onClick={() => {
            setDeleteReason('')
            setSelectAd(null)
            setModalDeleteIsOpen(false)
          }}>
            Retour
          </button>
          <button className="button-green" onClick={() => handleReject(selectAd)}>
            Valider
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default PendingAds;