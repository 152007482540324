import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import { readAndCompressImage } from 'browser-image-resizer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import './style.css';
import { Spinner } from 'react-bootstrap';
import useNavigateCustom from '../../hooks/useNavigateCustom';

const SignupForm = () => {
    const { register, handleSubmit, watch, formState: { errors }, getValues, reset } = useForm();
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [profileImage, setProfileImage] = useState(null);
    const [emailError, setEmailError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [imagePreviews, setImagePreviews] = useState(null);

    const [showPassword, setShowPassword] = useState(false);

    const { navigateHome } = useNavigateCustom()

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const isProfessional = watch('professional');

    const onSubmit = async (data) => {
        try {
            const formData = new FormData();
            Object.keys(data).forEach((key) => {
                if (key !== 'address') {
                    formData.append(key, data[key]);
                } else {
                    Object.keys(data.address).forEach((subKey) => {
                        formData.append(`address[${subKey}]`, data.address[subKey]);
                    });
                }
            });
            if (profileImage) {
                formData.append('profileImage', profileImage);
            }
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/register`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 201) {
                reset();
                setModalIsOpen(true);
            }
        } catch (error) {
            console.error(error.response.data.message);
            if (error.response.data.message === "User already exists") {
                setEmailError('Cette adresse email est déjà utilisée');
            }
        } finally {
            setLoading(false)
        }
    };

    const validateSiret = (siret) => {
        if (!/^\d{14}$/.test(siret)) return false;

        let sum = 0;
        for (let i = 0; i < siret.length; i++) {
            let num = parseInt(siret[i]);
            if (i % 2 === 0) {
                num *= 2;
                if (num > 9) num -= 9;
            }
            sum += num;
        }

        return sum % 10 === 0;
    }

    const handleImageChange = async (event) => {

        const config = {
            quality: 0.7,
            maxWidth: 800,
            maxHeight: 800,
            autoRotate: false,
            debug: true
        };

        if (event.target.files && event.target.files[0]) {
            const compressedFile = await readAndCompressImage(event.target.files[0], config);

            setProfileImage(compressedFile);

            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreviews(reader.result);
            };
            reader.readAsDataURL(compressedFile);
        }
    };


    return (
        <div className="signup-form">
            <p className="form-title"><span className="text-boz">Bienvenue</span> Créer votre compte pour <span className="text-boz-red">commencer !</span></p>

            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-row">
                    <div>
                        <label htmlFor="firstName">Prénom  <span className='required-star'>*</span></label>
                        <input
                            id="firstName"
                            type="text"
                            {...register('firstName', { required: 'Veuillez entrer votre prénom' })}
                        />
                        {errors.firstName && <p className="form-error">{errors.firstName.message}</p>}
                    </div>
                    <div>
                        <label htmlFor="lastName">Nom  <span className='required-star'>*</span></label>
                        <input
                            id="lastName"
                            type="text"
                            {...register('lastName', { required: 'Veuillez entrer votre nom' })}
                        />
                        {errors.lastName && <p className="form-error">{errors.lastName.message}</p>}
                    </div>
                </div>

                <div>
                    <label htmlFor="email">Email  <span className='required-star'>*</span></label>
                    <input
                        id="email"
                        type="email"
                        {...register('email', {
                            required: 'Veuillez entrer votre email',
                            pattern: {
                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                message: "Adresse e-mail invalide"
                            }
                        })}
                    />
                    {errors.email && <p className="form-error">{errors.email.message}</p>}
                    {emailError && <p className="form-error">{emailError}</p>}
                </div>
                <div>
                    <label htmlFor="profileImage">Image de profil</label>
                    <div style={{ display: 'flex' }}>
                        <input
                            id="profileImage"
                            type="file"
                            {...register('profileImage')}
                            onChange={handleImageChange}
                        />
                        {imagePreviews && <img src={imagePreviews} style={{ borderRadius: '50%', width: '50px', height: '50px', marginLeft: '1rem' }} alt="Profil" />}
                    </div>
                </div>
                <div>
                    <label htmlFor="email">Téléphone  <span className='required-star'>*</span></label>
                    <input
                        id="phone"
                        type="text"
                        {...register('phone')}
                    />
                    {errors.phone && <p className="form-error">{errors.phone.message}</p>}
                </div>
                <div className="form-row">

                    <div>
                        <label htmlFor="password"> Mot de passe  <span className='required-star'>*</span></label>
                        <div style={{ position: 'relative' }}>
                            <input
                                id="password"
                                type={showPassword ? "text" : "password"}
                                {...register('password', {
                                    required: 'Veuillez entrer votre mot de passe',
                                    pattern: {
                                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[~`!@#$%^&*()\-+={[}\]|\\:;"'<,>.?\/])[\w~`!@#$%^&*()\-+={[}\]|\\:;"'<,>.?\/]{6,}$/,
                                        message: "Le mot de passe doit comprendre au moins une lettre minuscule, une lettre majuscule, un chiffre et un caractère spécial ( ~`!@#$ %^&*()_-+={[}]|\\:; »'<,>.?/), et doit comporter au moins 6 caractères."
                                    }
                                })}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                onClick={togglePassword}
                                style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
                            />
                        </div>
                        {errors.password && <p className="form-error">{errors.password.message}</p>}
                    </div>
                    <div>
                        <label htmlFor="passwordConfirm">Confirmer le mot de passe <span className='required-star'>*</span></label>
                        <div style={{ position: 'relative' }}>
                            <input
                                id="passwordConfirm"
                                type={showPassword ? "text" : "password"}
                                {...register('passwordConfirm', {
                                    required: 'Veuillez confirmer votre mot de passe',
                                    validate: value => value === getValues().password || 'Les mots de passe ne correspondent pas.'
                                })}
                            />
                            <FontAwesomeIcon
                                icon={showPassword ? faEyeSlash : faEye}
                                onClick={togglePassword}
                                style={{ position: 'absolute', right: '10px', top: '10px', cursor: 'pointer' }}
                            />
                            {errors.passwordConfirm && <p className="form-error">{errors.passwordConfirm.message}</p>}
                        </div>
                    </div>
                </div>

                <div className="form-row">
                    <div>
                        <label htmlFor="city">Ville  <span className='required-star'>*</span></label>
                        <input
                            id="city"
                            type="text"
                            {...register('address.city', { required: 'Veuillez entrer le nom de votre ville' })}
                        />
                        {errors.address?.city && <p className="form-error">{errors.address.city.message}</p>}
                    </div>
                    <div>
                        <label htmlFor="postalCode">Code postal  <span className='required-star'>*</span></label>
                        <input
                            id="postalCode"
                            type="text"
                            {...register('address.postalCode', { required: 'Veuillez entrer votre code postal' })}
                        />
                        {errors.address?.postalCode && <p className="form-error">{errors.address.postalCode.message}</p>}
                    </div>
                </div>
                <div>
                    <label className="mb-4" htmlFor="cgu">J'accepte les <a className="cgu-link" href="https://jevendsmonpotager.fr/index.php/cgu/" target="_blank">conditions générales d'utilisation</a></label>
                    <div style={{ display: 'flex' }}>
                        <input
                            id="cgu"
                            type="checkbox"
                            {...register('cgu', { required: 'Vous devez accepetez les conditions générales d\'utilisation' })}
                        />
                    </div>
                    {errors.cgu && <p className="form-error" style={{ textAlign: 'center' }}>{errors.cgu.message}</p>}
                </div>
                <div>
                    <label htmlFor="professional">Êtes vous un professionnel ?</label>
                    <input
                        id="professional"
                        type="checkbox"
                        {...register('professional')}
                    />
                </div>
                {isProfessional && (
                    <>
                        <div>
                            <label htmlFor="companyName">Nom de l'entreprise  <span className='required-star'>*</span></label>
                            <input
                                id="companyName"
                                type="text"
                                {...register('companyName', { required: 'Veuillez entrer le nom de votre entreprise' })}
                            />
                            {errors.companyName && <p className="form-error">{errors.companyName.message}</p>}
                        </div>
                        <div>
                            <label htmlFor="companySiret">Numéro de SIRET  <span className='required-star'>*</span></label>
                            <input
                                id="companySiret"
                                type="text"
                                {...register('companySiret', { required: 'Veuillez entrer votre numéro de SIRET', validate: validateSiret })}
                            />
                            {errors.companySiret && <p className="form-error" d>{errors.companySiret.message || 'Siret invalide'}</p>}
                        </div>
                    </>
                )}
                <button type="submit" className="button-green">{loading ? <Spinner as="span" animation="border" /> : 'Créer un compte'}</button>
            </form>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                className="modal-container"
            >
                <h2 className="signup-modal-title">Bienvenue !</h2>
                <p className="signup-modal-button-texte">Un email a été envoyé à votre adresse. <br />Veuillez le vérifier pour activer votre compte.</p>
                <div className="signup-modal-actions">
                    <button className="button-green" onClick={() => { setModalIsOpen(false); navigateHome(); }}>J'ai compris !</button>
                </div>
            </Modal>
        </div >
    );
};

export default SignupForm;