// src/components/PendingAds.js
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import './style.css';
import { toast } from 'react-toastify';

const DeletedAds = () => {
  const [deletedAds, setDeletedAds] = useState([]);
  const navigate = useNavigate();
  const token = localStorage.getItem('token');

  const fetchDeletedAds = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/admin/adverts/deleted`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();

      if (response.ok) {
        setDeletedAds(data);
      } else {
        console.error('Erreur lors de la récupération des annonces en attente:', data.error);
      }
    } catch (error) {
      console.error('Erreur lors de la récupération des annonces en attente:', error);
    }
  };

  useEffect(() => {
    fetchDeletedAds();
  }, []);

  const handleActive = async (adId) => {
    try {
      await axios.put(`${process.env.REACT_APP_API_URL}/api/admin/advert/${adId}`, { status: "accepted" }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      toast.success('Annonce validée avec succès', {
        position: toast.POSITION.TOP_CENTER
      });
      fetchDeletedAds();

    } catch (error) {
      console.error('Erreur lors de l\'acceptation de l\'annonce:', error);
    }
  };

  const handleShowAdvert = (adId) => navigate(`/ad/${adId}`)

  return (
    <div className='admin-pending-annoncement'>
      {deletedAds?.length ? (
        <ul>
          {deletedAds.map((ad) => (
            <li key={ad._id}>
              {ad.productName} par {ad.user.firstName} {ad.user.lastName}
              <div>{ad.mainType === 'asked' ? 'Demande' : 'Offre'}</div>
              {ad.user.professional && <span className="badge-pro-simple">PRO</span>}
              <button onClick={() => handleShowAdvert(ad._id)}>Voir l'annonce</button>
              <button onClick={() => alert(ad.deleteReason)}>Raison</button>
              <button onClick={() => handleActive(ad._id)}>Activer</button>

            </li>
          ))}
        </ul>
      ) :
        <p>-</p>
      }
    </div>
  );
};

export default DeletedAds;